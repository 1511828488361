import "./navbar.css"
import * as NavbarStyles from "./navbar.module.css"
import { A, AppLink } from "@ui-components/link"
import React, { useEffect, useState } from "react"
import Button from "@ui-components/button"
import LogoShortOnLight from "@assets/logo_short-on-light.svg"
import LogoShortOnDark from "@assets/logo_short-on-dark.svg"
import LogoWideOnLight from "@assets/logo_wide-on-light.svg"
import LogoWideOnDark from "@assets/logo_wide-on-dark.svg"
import ChinaLogoWideOnDark from "@assets/china_logo_wide-on-dark.svg"
import ChinaLogoWideOnLight from "@assets/china_logo_wide-on-light.svg"
import { useTranslation } from "react-i18next"
import { useCookies } from "react-cookie"

const Navbar = () => {
  const [navbarOpened, setNavbarOpened] = useState(false)
  const { t } = useTranslation(["partials", "common"])
  const [ cookie ] = useCookies(["shortio_authenticated"])

  useEffect(() => {
    if (navbarOpened) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "initial"
    }
  }, [navbarOpened])

  useEffect(() => {
      if (cookie.shortio_authenticated) {
        var authElement = document.querySelector('.auth-block');
        var dashboardElement = document.querySelector('.dashboard-block');
        authElement!.classList.add('hidden');
        dashboardElement!.classList.remove('hidden');
      }
  }, [cookie.shortio_authenticated]);

  return (
    <nav className={`navBar ${navbarOpened ? "navBar_opened" : ""}`}>
      <div className="navBar__logoBox">
        <AppLink to="/">
          <picture>
            <source
              srcSet={LogoShortOnDark}
              media="(max-width: 500px) and (prefers-color-scheme: dark)"
            />
            <source
              srcSet={process.env.BUILD_MODE === "china" ? ChinaLogoWideOnDark : LogoWideOnDark}
              media="(prefers-color-scheme: dark)"
            />
            <source srcSet={LogoShortOnLight} media="(max-width: 500px)" />
            <img
              src={process.env.BUILD_MODE === "china" ? ChinaLogoWideOnLight : LogoWideOnLight}
              alt="Short.io link shortener"
              className="navBar__logoImage"
            />
          </picture>
        </AppLink>
        <button
          className={`menuButton ${
            navbarOpened ? "menuButton_close" : "menuButton_open"
          }`}
          onClick={() => setNavbarOpened(!navbarOpened)}
        ></button>
      </div>

      <ul className="navBarMenu">
        <li className="navBarMenuItem">
          <AppLink
            to="/pricing"
            className="navBarMenuItem__link"
            activeClassName="navBarMenuItem__link_active"
          >
            {t("navbar-menu-pricing")}
          </AppLink>
        </li>
        <li className="navBarMenuItem">
          <AppLink
            to="/features"
            className="navBarMenuItem__link"
            activeClassName="navBarMenuItem__link_active"
          >
            {t("navbar-menu-features")}
          </AppLink>
        </li>
        <li className="navBarMenuItem">
          <AppLink
            to="/integrations/"
            className="navBarMenuItem__link"
            activeClassName="navBarMenuItem__link_active"
          >
            {t("navbar-menu-integrations")}
          </AppLink>
        </li>
        {/* <li className="navBarMenuItem">
                <a href="" className="link navBarMenuItem__link">
                    {t('navbar-menu-domains')}
                </a>
            </li> */}
        <li className="navBarMenuItem">
          <AppLink
            to="/enterprise/"
            className="link navBarMenuItem__link"
            activeClassName="navBarMenuItem__link_active"
          >
            {t("navbar-menu-enterprise")}
          </AppLink>
        </li>
        <li className="navBarMenuItem">
          <A
            href="https://blog.short.io/"
            className="navBarMenuItem__link"
            target="_blank"
          >
            {t("navbar-menu-blog")}
          </A>
        </li>
        <li className="navBarMenuItem">
          <A
            href="https://help.short.io/"
            className="navBarMenuItem__link"
            target="_blank"
          >
            {t("navbar-menu-help")}
          </A>
        </li>
      </ul>

      <div className="navBarActions auth-block">
        <A
          href={`https://app.${process.env.DOMAIN_NAME}/public/login`}
          className={NavbarStyles.navBarActionsLink}
        >
          {t("common:log-in")}
        </A>
        <Button
          href={`https://app.${process.env.DOMAIN_NAME}/public/register`}
          outlined
          className={NavbarStyles.navBarActionsButton}
        >
          {t("common:get-started")}
        </Button>
      </div>

      <div className="navBarActions dashboard-block hidden">
        <a
          href={`https://app.${process.env.DOMAIN_NAME}/`}
          className="button button_outlined navBarActions__button_outlined"
        >
          {t("navbar-open-dashboard")}
        </a>
      </div>
    </nav>
  )
}

export default Navbar
