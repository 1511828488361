import './link.css'

import React, { PropsWithChildren } from "react"
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";
import { DEFAULT_LANGUAGE } from 'components/rootElement';


interface AppLinkProps {
    to: string;
    className?: string;
    language?: string;
    activeClassName?: string;
}

const stripLanguage = (path: string) => path.replace(/^\/\w{2}(\/|$)/, '/');

export const AppLink = ({ children, className, to, activeClassName }: PropsWithChildren<AppLinkProps>) => {
    const { i18n } = useTranslation();
    const localizedTo = (i18n.language === DEFAULT_LANGUAGE ? to : `/${i18n.language}${to}`).replace(/\/$/, '') || '/';
    const location = useLocation();
    const linkClassName = classnames({
        link: true,
        [className!]: className,
        [activeClassName!]: stripLanguage(location.pathname) === stripLanguage(localizedTo)
    });
    return <Link to={localizedTo} className={linkClassName}
    >
        {children}
    </Link>
}

export const A = ({ children, className, href, target }: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => (
    <a href={href} target={target} rel={target === '_blank' ? 'noreferrer' : undefined} className={classnames("link", className)}>
        {children}
    </a>
)