import { AppLink } from '@ui-components/link'
import './button.css'
import classnames from 'classnames'
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react"

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & AnchorHTMLAttributes<HTMLAnchorElement> & {
    outlined?: boolean,
    solid?: boolean,
}

const Button = ({ children, className, href, outlined, solid, ...rest }: ButtonProps) => {
    const buttonClassname = classnames({
        button: true,
        button_outlined: outlined,
        button_solid: solid,
        [className!]: className,
    });
    if (href && href.startsWith("/")) {
        return <AppLink to={href} className={buttonClassname}>{children}</AppLink>
    }
    return href ?
        <a href={href} className={buttonClassname}>
            {children}
        </a> :
        <button className={buttonClassname} {...rest}>
            {children}
        </button>
}

export default Button