import Layout from "components/layout";
import { wrapRootElement as baseWrapRootElement } from "components/rootElement";
import type { GatsbyBrowser } from "gatsby"
import React from "react"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => <Layout location={props.location} pageContext={props.pageContext}>{element}</Layout>;

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = baseWrapRootElement;

export {
    wrapRootElement,
}