import { I18nextProvider, useSSR } from "react-i18next"
import i18n, { InitOptions } from "i18next"
import React from "react";
import config from "../../i18n/config.json";
import { LiveChatLoaderProvider } from "react-live-chat-loader";

export const DEFAULT_LANGUAGE = process.env.BUILD_MODE === 'china' ? 'zh' : 'en'
    

const getLocale = (pathname: string) => {
    if (typeof location !== "undefined") {
        pathname = location.pathname
    }
    
    if (pathname && pathname.split("/")[1].length == 2) {
        return pathname.split("/")[1]
    }
}

interface InitI18NProps {
    initialI18nStore: any;
    initialLanguage: string;
    children: React.ReactNode;
}

export function InitI18N({ initialI18nStore, initialLanguage, children }: InitI18NProps) {
    useSSR(initialI18nStore, initialLanguage);
  
    return children;
  }

interface WrapRootElementProps {
    element: React.ReactNode;
    pathname: string;
}
const i18nextOptions = {
    fallbackLng: DEFAULT_LANGUAGE,
    lng: DEFAULT_LANGUAGE,
    ns: ["main-page", "pricing", "features-list", "features", "integrations", "enterprise", "about-us", "partials", "common", "404"],
    initImmediate: false,
    supportedLngs: config.map(language => language.code),
    interpolation: {
        escapeValue: false,
    },
} as InitOptions;
i18nextOptions.resources = {}
for (const locale of config) {
    (i18nextOptions.ns as string[]).forEach((name: string) => {
        const data = require(`../../i18n/react-i18next/${locale.code}/${name}.json`)
        i18nextOptions.resources![locale.code] ??= {}
        i18nextOptions.resources![locale.code][name] = data;
    })
}
i18n.init(i18nextOptions)

export const wrapRootElement = ({ element, pathname, ...rest }: WrapRootElementProps) => {
    return <LiveChatLoaderProvider provider="intercom" providerKey="rmf95u7h"><I18nextProvider i18n={i18n}><InitI18N initialI18nStore={i18nextOptions.resources} initialLanguage={getLocale(pathname) ?? DEFAULT_LANGUAGE}>
        {element}</InitI18N></I18nextProvider></LiveChatLoaderProvider>
  }