import './footer.css'

import { FooterLink, LanguageLink, FooterExternalLink } from "./footer-links"

import { A } from '@ui-components/link'
import FacebookIcon from "@assets/socials/fb.svg"
import FooterImageAVIF from "@assets/footer/medium-footer.avif"
import FooterImagePNG from "@assets/footer/medium-footer.png"
import FooterImageWEBP from "@assets/footer/medium-footer.webp"
import LinkedinIcon from "@assets/socials/linkedin.svg"
import LogoWideOnDark from "@assets/logo_wide-on-dark.svg"
import React from "react"
import TwitterIcon from "@assets/socials/twitter.svg"
import { useTranslation } from "react-i18next"
import * as FooterCSS from "./footer.module.css"
import { PageProps } from 'gatsby'
import i18nConfig from '../../../../i18n/config.json';
import { useCookies } from 'react-cookie'
import { Intercom } from 'react-live-chat-loader'

interface FooterProps {
    location: PageProps['location']
}

declare global {
    interface Window {
        Intercom: any
    }
}

const Footer = ({ location }: FooterProps) => {
    const [, setCookie] = useCookies(["override_language"]);
    const { t, i18n } = useTranslation(['partials', 'common']);

    const isCurrentLocation = (localeCode: string) => {
        if (localeCode === i18n.language && location.pathname.startsWith('/') && !location.pathname.match(/^\/\w{2}(\/|$)/))
            return true
        return location.pathname.startsWith(`/${localeCode}`)
    };

    return <footer className={FooterCSS.footer}>
        <div className={`section ${FooterCSS.footerMain}`}>
            <div className={FooterCSS.footerMainPart}>
                <img src={LogoWideOnDark} className={FooterCSS.footerMainPart__logo} alt="Short.io logo" loading="lazy" />
                <A href="mailto:info@short.io" className={FooterCSS.footerMainPart__email}>info@short.io</A>
                <picture className="footerMainPart__image">
                    <source type="image/avif" srcSet={FooterImageAVIF} />
                    <source type="image/webp" srcSet={FooterImageWEBP} />
                    <img src={FooterImagePNG} alt="Short.io help" loading="lazy" />
                </picture>
            </div>
            <div className="footerColumn">
                <span className="footerColumn__title">{t('footer-product.title')}</span>
                <ul className="footerLinksList">
                    <li className="footerLinksListItem">
                        <FooterLink to="/features">{t('footer-product.links.features')}</FooterLink>
                    </li>
                    <li className="footerLinksListItem">
                        <FooterLink to="/integrations">{t('footer-product.links.integrations')}</FooterLink>
                    </li>
                    <li className="footerLinksListItem">
                        <FooterLink to="/pricing">{t('footer-product.links.pricing')}</FooterLink>
                    </li>
                </ul>
            </div>
            <div className="footerColumn">
                <span className="footerColumn__title">{t('footer-resources.title')}</span>
                <ul className="footerLinksList">
                    {/* <li className="footerLinksListItem">
                        <a href="" className="link footerLinksListItem__link">Register Domain Names</a>
                    </li> */}
                    <li className="footerLinksListItem">
                        <FooterExternalLink href="https://developers.short.io/" target="_blank">
                            {t('footer-resources.links.api')}
                        </FooterExternalLink>
                    </li>
                    <li className="footerLinksListItem">
                        <FooterExternalLink href="https://help.short.io/en/collections/2363480-useful-articles" target="_blank">
                            {t('footer-resources.links.faq')}
                        </FooterExternalLink>
                    </li>
                    <li className="footerLinksListItem">
                        <FooterExternalLink href="https://status.short.io/" target="_blank">{t('footer-resources.links.system-status')}</FooterExternalLink>
                    </li>
                </ul>
            </div>
            <div className="footerColumn">
                <span className="footerColumn__title">{t('footer-about-us.title')}</span>
                <ul className="footerLinksList">
                    <li className="footerLinksListItem">
                        <FooterLink to="/about-us">{t('footer-about-us.links.our-team')}</FooterLink>
                    </li>
                    <li className="footerLinksListItem">
                        <FooterLink to="/about-us">{t('footer-about-us.links.about-company')}</FooterLink>
                    </li>
                    <li className="footerLinksListItem">
                        <FooterExternalLink href="" onClick={() => window.Intercom('showNewMessage')}>{t('footer-about-us.links.contact-us')}</FooterExternalLink>
                    </li>
                </ul>
            </div>




            <div className="footerColumn">
                <span className="footerColumn__title">{t('footer-community.title')}</span>
                <ul className="footerLinksList">
                    <li className="footerLinksListItem">
                        <FooterExternalLink href="https://blog.short.io/" target="_blank">
                            {t('footer-community.links.blog')}
                        </FooterExternalLink>
                    </li>
                    <li className="footerLinksListItem">
                        <FooterExternalLink href="https://support.short.io/" target="_blank">
                            {t('footer-community.links.help-center')}
                        </FooterExternalLink>
                    </li>
                    <li className="footerLinksListItem">
                        <FooterExternalLink href="mailto:abuse@short.io">{t('footer-community.links.report-abuse')}</FooterExternalLink>
                    </li>
                </ul>
            </div>
            <span className="languagesHeading">Choose a Language:</span>
            <ul className={FooterCSS.languages}>
                {i18nConfig.map((locale) => (
                    <li key={locale.code} className="language">
                        <LanguageLink onClick={() => setCookie('override_language', locale.code)} to={ locale.code === 'en' ? '/' : `/${locale.code}`} active={isCurrentLocation(locale.code)}>
                            {locale.localName}
                        </LanguageLink>
                    </li>))
                }
            </ul>
            <div className={FooterCSS.socials}>
                <a href="https://twitter.com/short_io" className="socialLink">
                    <img src={TwitterIcon} alt="twitter icon" loading="lazy" />
                </a>
                <a href="https://www.linkedin.com/company/short.cm" className="socialLink">
                    <img src={LinkedinIcon} alt="linkedin icon" loading="lazy" />
                </a>
                <a href="https://www.facebook.com/shortio.links" className="socialLink">
                    <img src={FacebookIcon} alt="facebook icon" loading="lazy" />
                </a>
            </div>
        </div>
        <div className="section footerBottom">
            <span className="copyright">{process.env.BUILD_MODE === 'china' ? '©上海索特艾欧科技有限公司' : t('footer-copyright')}</span>
            <A href="/terms" className="footerBottom__link">
                {t('footer-terms-conditions')}
            </A>
            <A href="/privacy" className="footerBottom__link">
                {t('footer-privacy-gdpr')}
            </A>
        </div>
        <Intercom color='rgb(27, 181, 132)' />
    </footer>
}

export default Footer
