import "./layout.css"
import "./normalize.css"
import React, { PropsWithChildren, useState } from "react"

import { useEffect } from "react"
import type { PageProps } from "gatsby"

import Footer from "@partials/footer"
import Navbar from "@partials/navbar"
import { Script } from "gatsby"
import { useCookies } from "react-cookie"
import { useTranslation } from "react-i18next"
import { useLocation } from "@gatsbyjs/reach-router"
import i18nConfig from "../../i18n/config.json"
import { DEFAULT_LANGUAGE } from "./rootElement"

const useGclidAndMsclkid = () => {
  const [, setCookie] = useCookies(["shortio_gclid", "shortio_msclkid"])
  const location = useLocation()
  useEffect(() => {
    const url = new URL(location.href)
    const searchParams = url.searchParams
    const gclid = searchParams.get("gclid")
    if (gclid) {
      setCookie("shortio_gclid", gclid, { domain: ".short.io", secure: true })
    }
  }, [])

  useEffect(() => {
    const url = new URL(location.href)
    const searchParams = url.searchParams
    const clid = searchParams.get("msclkid")
    if (clid) {
      setCookie("shortio_msclkid", clid, {
        domain: ".short.io",
        secure: true,
      })
    }
  }, [])
}

const Header = (): React.ReactNode => {
  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="google-site-verification"
        content="rAOMqVkDGHqKOew1M59pN-C0UUzy9W5o72dUWD61JT8"
      />
      <meta name="slack-app-id" content="A3929QF8W" />
      <meta
        name="norton-safeweb-site-verification"
        content="79ki4e4krr61o3l3e2bf9eclx7-qqrrs8x8svxb-vp--qmgwq9h8uqt8kgc81rt7l1-77e7um6wqv0ncnyo84be4k5me4s-ul95toevmugxz9h-dj1ji90sirgp70tmt"
      />
      <meta name="og:type" content="website" />
    </>
  )
}

const useSetLocale = () => {
  const location = useLocation();
  const [{ override_language: overrideLanguage }] = useCookies([
    "override_language",
  ])
  const { i18n } = useTranslation()
  if (typeof window === "undefined") {
    if (/^\/\w{2}(\/|$)/.test(location.pathname)) {
      i18n.changeLanguage(location.pathname.split("/")[1])
    } else if (i18n.language !== DEFAULT_LANGUAGE) {
      i18n.changeLanguage(DEFAULT_LANGUAGE)
    }
  }
  
  useEffect(() => {
    if (/^\/\w{2}(\/|$)/.test(location.pathname)) {
      i18n.changeLanguage(location.pathname.split("/")[1])
      return
    }

    if (overrideLanguage) {
      i18n.changeLanguage(overrideLanguage)
      return;
    }

    const browserLanguages = navigator.languages ?? [navigator.language]
    for (const browserLanguage of browserLanguages.map(
      language => language.split("-")[0],
    )) {
      if (browserLanguage === i18n.language) {
        return
      }
      if (browserLanguage === DEFAULT_LANGUAGE) {
        return
      }
      for (const supportedLanguage of i18nConfig) {
        if (supportedLanguage.code === browserLanguage) {
          i18n.changeLanguage(browserLanguage)
          return
        }
      }
    }
  }, [location.pathname, i18n.language, overrideLanguage])
}

const Layout = ({
  children,
  location,
}: PropsWithChildren<PageProps>) => {
  useGclidAndMsclkid()
  useSetLocale()

  return (
    <>
      <Header />
      <Script
        nonce="@@=NONCE=@@"
        id="recaptchaScript"
        src="https://recaptcha.net/recaptcha/api.js?render=6LdO8JcUAAAAAJWQi_B27yDFuShbD2Cvq4AqcOCQ"
      />
      <Script id="gtmDataLayer" nonce="@@=NONCE=@@">{`
            // Initialize the data layer for Google Tag Manager (this should mandatorily be done before the Cookie Solution is loaded)
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            // Default consent mode is "denied" for both ads and analytics, but delay for 2 seconds until the Cookie Solution is loaded
            gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                wait_for_update: 2000 // milliseconds
            });
            // Improve ad click measurement quality (optional)
            gtag('set', 'url_passthrough', true);
            // Further redact your ads data (optional)
            gtag("set", "ads_data_redaction", true);
        `}</Script>
      <Script id="gtmScript" nonce="@@=NONCE=@@">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KRWKR2');`}</Script>
      <Navbar />
      <main>{children}</main>
      <Footer location={location} />
    </>
  )
}

export default Layout
