import { A, AppLink } from '@ui-components/link'

import { Link } from "gatsby"
import React from "react"
import * as FooterCSS from "./footer.module.css"
import { PropsWithChildren } from 'react'
import classNames from 'classnames'

interface FooterLinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    to: string
}

export const FooterLink = ({ to, children }: PropsWithChildren<FooterLinkProps>) => (
    <AppLink to={to} className={FooterCSS.footerLinksListItem__link}>
        {children}
    </AppLink>
)

interface FooterExternalLinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    href: string
    target?: string
}

export const FooterExternalLink = ({ href, target, children, ...rest }: PropsWithChildren<FooterExternalLinkProps>) => (
    <A href={href} className={FooterCSS.footerLinksListItem__link} target={target} {...rest}>
        {children}
    </A>
)

interface LanguageLinkProps {
    to: string
    active: boolean
    onClick: () => void
}

export const LanguageLink = ({ to, active, children, ...props }: PropsWithChildren<LanguageLinkProps>) => (
    <Link to={to} className={classNames([`link`, `language__link`, active ? 'language__link_active' : null])} {...props}>
        {children}
    </Link>
)
